<template>
  <v-dialog fullscreen transition="slide-x-transition" v-model="modal">
    <div class="expande-horizontal expande-vertical-vh vitrify centraliza">
      <v-flex xs12 md10>
        <div class="animate__animated expande-horizontal column">
          <div class="expande-horizontal centraliza wrap py-6 pb-0">
            <v-flex xs12 md8>
              <div class="expande-horizontal wrap">
                <v-card
                  color=""
                  class="expande-horizontal"
                  style="min-height: 77vh;"
                >
                  <div class="expande-horizontal">
                    <v-flex
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="pa-6"
                      xs12
                      md6
                    >
                      <div
                        style="min-height: 60vh;"
                        class="expande-horizontal pa-6 centraliza"
                      >
                        <LottieAnimation
                          v-if="loading === 'loading_rocket'"
                          class="pa-6"
                          ref="anim"
                          :loop="true"
                          :animationData="
                            require('@/apps/shared/assets/loading_rocket.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'success'"
                          class="pa-6"
                          ref="anim2"
                          :animationData="
                            require('@/apps/shared/assets/activate_account_success.json')
                          "
                        />
                        <LottieAnimation
                          v-if="loading === 'man_typing'"
                          :loop="true"
                          class="pa-6 animate__fadeInUp"
                          ref="anim3"
                          :animationData="
                            require('@/apps/shared/assets/expense.json')
                          "
                        />
                      </div>
                    </v-flex>
                    <v-flex class="pa-6" xs12 md6>
                      <v-window v-model="step">
                        <v-window-item :value="1">
                          <div
                            style="height: 57vh;"
                            transition="fade"
                            class="pt-6 mt-3 expande-horizontal centraliza fonte animate__fadeInLeft animate__delay-2s wrap"
                          >
                            <v-flex class="pa-5 pb-2" xs12>
                              <div
                                class="expande-horizontal column animate__fadeIn"
                              >
                                <h2 style="color: #3EB4DF;">
                                  Bem Vindo!
                                </h2>
                                <span class="fonte grey--text"
                                  >Escolha qual é o tipo da conta...</span
                                >
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <v-flex xs12 class="">
                                <v-list
                                  v-if="loading === 'man_typing'"
                                  nav
                                  two-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <v-list-item @click="selectType('to_pay')">
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="16"
                                      color="red"
                                      >mdi-arrow-up</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        Conta que eu tenho que pagar
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item
                                    @click="selectType('to_receive')"
                                  >
                                    <v-icon
                                      class="mr-3 ml-3"
                                      size="16"
                                      color="green"
                                      >mdi-arrow-down</v-icon
                                    >
                                    <v-list-item-content>
                                      <v-list-item-subtitle
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        Conta que eu tenho que Receber
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                <v-btn
                                  @click="close"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-6"
                                  >Cancelar
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </v-flex>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="2">
                          <div
                            transition="fade"
                            v-if="loading === 'man_typing'"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #3EB4DF;">
                                  Tipo de Despesa
                                </h2>
                                <span class="grey--text fonte">
                                  Selecione o tipo de despesa abaixo
                                </span>
                              </div>
                            </v-flex>
                            <v-flex class="pa-3" xs12>
                              <div
                                class="expande-horizontal"
                                style="max-height: 300px; overflow: auto; overflow-x: hidden;"
                              >
                                <v-list
                                  nav
                                  three-line
                                  class="animate__fadeInRight animate__animated pa-0 ma-0 pb-6"
                                >
                                  <template
                                    v-for="expense_type in expense_types"
                                  >
                                    <v-list-item
                                      style="background-color: #f5f5f5; border-radius: 6px; margin-bottom: 6px; margin-right: 6px;"
                                      :key="expense_type.slug"
                                      @click="
                                        selectTypeExpense(expense_type.slug)
                                      "
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="fonte fonte-mini fonte-bold font-default-color"
                                        >
                                          {{ expense_type.title }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ expense_type.description }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle></v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </div>
                              <div class="expande-horizontal">
                                <v-btn
                                  @click="step = 1"
                                  dark
                                  text
                                  class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize mt-3"
                                >
                                  <v-icon size="16" class="mt-1" color="grey"
                                    >mdi-chevron-left</v-icon
                                  >
                                  voltar
                                </v-btn>
                                <v-spacer></v-spacer>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                        <v-window-item :value="3">
                          <div
                            transition="fade"
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #3EB4DF;">
                                  Detalhes de pagamento
                                </h2>
                                <span class="fonte mt-3 grey--text">
                                  Informe detalhes sobre como esta conta será
                                  cobrada.
                                </span>
                              </div>
                            </v-flex>

                            <v-form ref="form">
                              <div class="expande-horizontal wrap">
                                <v-flex xs12 class="pa-3">
                                  <v-text-field
                                    outlined
                                    ref="accountDescription"
                                    dense
                                    @keyup.enter="$refs.inputValue.focus()"
                                    v-model="get_invoice.description"
                                    label="Descrição da conta"
                                    :placeholder="placeholderDescription"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                  <div class="expande-horizontal wrap">
                                    <v-flex class="pa-3 pr-0" xs12 md7>
                                      <v-text-field
                                        outlined
                                        dense
                                        ref="inputValue"
                                        @keyup.enter="
                                          $refs.inputPaymentDay.focus()
                                        "
                                        v-model="get_invoice.value"
                                        label="Valor"
                                        prefix="R$"
                                        v-mask="['###.###.###.###,##']"
                                        placeholder="ex: 5"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex class="pa-3" xs12 md5>
                                      <v-text-field
                                        outlined
                                        dense
                                        ref="inputPaymentDay"
                                        v-model="get_invoice.payment_day"
                                        label="Vencimento"
                                        placeholder="ex: 5"
                                      ></v-text-field>
                                    </v-flex>
                                  </div>
                                </v-flex>
                                <v-flex class="pa-3" xs12>
                                  <v-select
                                    outlined
                                    dense
                                    :items="[
                                      'Pagamento Único',
                                      'Semanal',
                                      'Mensal',
                                      'Anual'
                                    ]"
                                    v-model="get_invoice.recurrent_type"
                                    label="Tipo de Recorrência"
                                    placeholder="ex: ex: Pagamento Único"
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12 class="pa-3">
                                  <div class="expande-horizontal">
                                    <v-btn
                                      @click="stepBack"
                                      dark
                                      text
                                      class="fonte animate__delay-2s animate__fadeIn grey--text text-capitalize"
                                    >
                                      <v-icon
                                        size="16"
                                        class="mt-1"
                                        color="grey"
                                        >mdi-chevron-left</v-icon
                                      >
                                      voltar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      @click="createInvoice"
                                      dark
                                      class="fonte"
                                      :color="$theme.secondary"
                                      >Avançar</v-btn
                                    >
                                  </div>
                                </v-flex>
                              </div>
                            </v-form>
                          </div>
                        </v-window-item>
                        <v-window-item :value="4">
                          <div
                            style="min-height: 57vh;"
                            class="mt-3 expande-horizontal centraliza fonte animate__fadeInLeft wrap"
                          >
                            <v-flex class="pa-3" xs12>
                              <div class="expande-horizontal column">
                                <h2 style="color: #3EB4DF;">
                                  Sua Conta está pronta
                                </h2>
                                <span class="fonte mt-3 grey--text">
                                  Veja o resumo abaixo
                                </span>
                              </div>
                            </v-flex>

                            <v-flex xs12>
                              <div class="expande-horizontal">
                                <v-list>
                                  <v-list-item
                                    style="border-radius: 6px; background: #DEF2FA;"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="fonte fonte-mini fonte-bold font-default-color"
                                      >
                                        Descrição: {{ get_invoice.description }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        Valor: R$ {{ get_invoice.value }}
                                      </v-list-item-subtitle>
                                      <v-list-item-subtitle>
                                        Dia do pagamento:
                                        {{ get_invoice.payment_day }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </div>
                            </v-flex>

                            <v-flex xs12>
                              <div class="expande-horizon tal column">
                                <v-btn
                                  block
                                  @click="open"
                                  :color="$theme.secondary"
                                  class="mb-3 fonte"
                                  dark
                                >
                                  Cadastrar Nova Conta
                                </v-btn>
                                <v-btn
                                  block
                                  text
                                  small
                                  :color="$theme.secondary"
                                  @click="close"
                                >
                                  Sair do cadastro
                                </v-btn>
                              </div>
                            </v-flex>
                          </div>
                        </v-window-item>
                      </v-window>
                    </v-flex>
                  </div>
                </v-card>
              </div>
            </v-flex>
          </div>
        </div>
      </v-flex>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LottieAnimation from "lottie-web-vue";
// import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  // components: { ModalSendArchive },
  data() {
    return {
      modal: false,
      loading: "",
      step: 1,
      expense_types: [
        {
          slug: "personal_expense",
          title: "Despesas de Pessoal",
          description:
            "Nesta conta sintética são contabilizados os gastos com pessoal, encargos e benefícios.",
          example: "ex: "
        },
        {
          slug: "contracted_services",
          title: "Serviços Contratados",
          description:
            "Registro de gastos com serviços contratados, tais como, contabilidade, advogados etc.",
          example: "ex: "
        },
        {
          slug: "sale_expense",
          title: "Despesa com Vendas",
          description:
            "Nesta conta são registrados gastos com comissões e tarifas.",
          example: "ex: "
        },
        {
          slug: "driver_expense",
          title: "Despesa com Veículos",
          description:
            "Registro de gastos com veículos, tais como, manutenção, seguro, IPVA, licenciamento etc.",
          example: "ex: "
        },
        {
          slug: "tax_expense",
          title: "Despesa Tributária",
          description:
            "Registro de gastos com IPTU, ICMS diferencial de alíquotas, impostos e taxas diversas."
        },
        {
          slug: "occupancy_expense",
          title: "Despesa com Ocupação",
          description:
            "Nesta conta será registrada os gastos com telefone, aluguéis e energia elétrica."
        }
      ]
    };
  },
  components: {
    LottieAnimation
  },
  computed: {
    ...mapGetters(["get_invoice", "getLoggedUser"]),
    placeholderDescription() {
      let description = "";
      if (!this.get_invoice.expense_type) {
        description = "Ex: Pagamento do Luiz...";
      }
      if (this.get_invoice.expense_type) {
        description = this.get_invoice.expense_type.description;
      }
      return description;
    }
  },
  watch: {
    "get_invoice.saved": function(val) {
      if (val) {
        this.setLoading("success");
        this.step = 4;
      }
    }
  },
  methods: {
    ...mapActions(["criar_invoice", "atualizar_invoice"]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_invoice._id ? this.atualizar_invoice() : this.criar_invoice();
      }
    },
    createInvoice() {
      this.setLoading("loading_rocket");
      this.criar_invoice();
    },
    selectType(type) {
      this.get_invoice.type = type;
      if (type === "to_pay") {
        this.step = 2;
      }
      if (type === "to_receive") {
        this.step = 3;
        setTimeout(() => {
          this.$refs.accountDescription.focus();
        }, 500);
      }
    },
    selectTypeExpense(type) {
      this.get_invoice.expense_type = type;
      this.step = 3;
      setTimeout(() => {
        this.$refs.accountDescription.focus();
      }, 500);
    },
    setLoading(state) {
      this.loading = "";
      setTimeout(() => {
        this.loading = state;
      }, 300);
    },
    open() {
      this.modal = false;
      this.modal = true;
      this.setLoading("");
      setTimeout(() => {
        this.setLoading("man_typing");
        this.step = 1;
        this.$store.commit("set_invoice", {
          saved: false
        });
      }, 200);
    },
    close() {
      this.modal = false;
    },
    stepBack() {
      if (this.get_invoice.type === "to_pay") {
        this.step = 2;
      } else {
        this.step = 1;
      }
    }
  }
};
</script>
