<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_invoice"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_invoice" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Registro de invoice
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_invoice">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nome
              </span>
              <v-text-field
                v-model="get_invoice.nome"
                dense
                solo
                flat
                outlined
                clearable
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="ex: Roberto"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Email
              </span>
              <v-text-field
                v-model="get_invoice.email"
                dense
                solo
                flat
                outlined
                clearable
                :color="$theme.primary"
                label="ex: meuemail@gmail.com"
              ></v-text-field>
            </v-flex>

            <div class="expande-horizontal">
              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  CPF
                </span>
                <v-text-field
                  v-model="get_invoice.cpf"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['###.###.###-##']"
                  clearable
                  :color="$theme.primary"
                  label="ex: 000.000.000-00"
                ></v-text-field>
              </v-flex>
            </div>

            <div class="expande-horizontal">
              <v-flex class="px-7" xs6>
                <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                  Telefone
                </span>
                <v-text-field
                  v-model="get_invoice.telefone"
                  dense
                  solo
                  flat
                  outlined
                  v-mask="['(##) # #### ####']"
                  clearable
                  :color="$theme.primary"
                  label="ex: (96) 9 8419-6827"
                ></v-text-field>
              </v-flex>
            </div>
            <v-flex class="px-7" xs6>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Nascimento
              </span>
              <v-text-field
                v-model="get_invoice.data_nascimento"
                dense
                solo
                flat
                outlined
                v-mask="['##/##/####']"
                clearable
                :color="$theme.primary"
                label="ex: 12/12/1984"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
export default {
  components: { ModalSendArchive },
  computed: {
    ...mapGetters([
      "get_invoice",
      "getProfessores",
      "get_modal_view_invoice",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "criar_invoice",
      "atualizar_invoice",
      "listarProfessores",
      "fecha_modal_view_invoice"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_invoice._id ? this.atualizar_invoice() : this.criar_invoice();
      }
    }
  },
  created() {
    this.listarProfessores();
  }
};
</script>
