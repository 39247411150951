<template>
  <v-flex class="pa-3" :style="`background: ${$theme.background};`" xs12>
    <v-flex xs12>
      <div class="expande-horizontal wrap">
        <v-flex class="pr-3 pb-6" xs12 md3>
          <v-card>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <h5 class="fonte">Quantidade</h5>
                <span class="fonte secondary-color fonte-bold"> {{ get_invoices.docs.length }} </span>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex class="pr-3 pb-6" xs12 md3>
          <v-card>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <h5 class="fonte">Total a Pagar</h5>
                <span class="fonte secondary-color fonte-bold"> R$ {{ get_invoices.total_a_pagar }} </span>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex class="pr-3 pb-6" xs12 md3>
          <v-card>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <h5 class="fonte">Total a Receber</h5>
                <span class="fonte secondary-color fonte-bold"> R$ {{ get_invoices.total_a_receber }} </span>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
        <v-flex class="pr-3 pb-6" xs12 md3>
          <v-card>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <h5 class="fonte">Balanço</h5>
                <span class="fonte secondary-color fonte-bold font-bold"> R$ {{ get_invoices.balanco }} </span>
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </div>
    </v-flex>
    <v-simple-table
      v-if="$vuetify.breakpoint.smAndUp"
      style="border: 1px solid #E5E5E5"
      dense
    >
      <template v-slot:default>
        <thead style="background: #D4D4D4">
          <tr class="fonte">
            <td class="font-weight-bold">Tipo</td>
            <td class="font-weight-bold">Status</td>
            <td class="font-weight-bold">#ID</td>
            <td class="font-weight-bold">Descrição</td>
            <td class="font-weight-bold">Dia do Pagamento</td>
            <td class="font-weight-bold">Categoria</td>
            <td class="font-weight-bold">Recorrência</td>
            <td class="font-weight-bold">Valor</td>
            <td class="font-weight-bold"></td>
          </tr>
        </thead>
        <tbody>
          <tr
            class="fonte"
            :style="
              `cursor: pointer; background: ${item.type === 'to_pay' ? '#FFCCD1' : '#C3E8C0'}`
            "
            v-for="(item, i) in get_invoices.docs"
            :key="item.id"
          >
            <!-- <td style="width: 10px"> <v-checkbox :color="$theme.primary" @click="setMarcado($event, item)" dense hide-details></v-checkbox> </td> -->
            <td style="width: 150px;" @click="abre_modal_view_invoice(item)">
              <v-icon
                class="mr-3"
                size="16"
                v-if="item.type === 'to_pay'"
                color="red"
                >mdi-arrow-up</v-icon
              >
              <v-icon class="mr-3" size="16" v-else color="green"
                >mdi-arrow-down</v-icon
              >
              {{ item.type | typeFilter }}
            </td>
            <td @click="abre_modal_view_invoice(item)">
              {{ item.status | typeStatus }}
            </td>
            <td
              style="width: 10px"
              @click="abre_modal_view_invoice(item)"
              class="font-weight-bold"
            >
              {{ item.id_invoice }}
            </td>
            <td @click="abre_modal_view_invoice(item)">
              {{ item.description }}
            </td>
            <td @click="abre_modal_view_invoice(item)">
              {{ item.payment_day }}
            </td>
            <td @click="abre_modal_view_invoice(item)">
              {{ item.expense_type | expenseFilter }}
            </td>
            <td @click="abre_modal_view_invoice(item)">
              {{ item.recurrent_type }}
            </td>
            <td @click="abre_modal_view_invoice(item)">{{ item.value }}</td>
            <td style="width: 10px">
              <v-btn
                @click="
                  createConfirmAction({
                    message: 'Deseja remover este invoice?',
                    icon: 'mdi-warning',
                    action: 'excluir_invoice',
                    action_value: item
                  })
                "
                icon
                ><v-icon> mdi-delete </v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-list v-else class="pa-0 ma-0" color="transparent">
      <template v-for="(item, index) in get_invoices.docs">
        <v-list-item
          @click="abre_modal_view_invoice(item)"
          style="border-radius: 6px; margin-bottom: 6px; border: 1px solid orange;"
          :key="item._id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.nome }}</v-list-item-title>
            <v-list-item-subtitle> CPF: {{ item.cpf }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-divider :key="index"></v-divider> -->
      </template>
    </v-list>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Template from "../../shared/components/Template.vue";
export default {
  components: { Template },
  computed: {
    ...mapGetters(["get_invoices"])
  },
  filters: {
    expenseFilter(value) {
      switch (value) {
        case "personal_expense":
          return "Despesa de Pessoal";
        case "contracted_services":
          return "Despesa Serviços Contratados";
        case "sale_expense":
          return "Despesa com Vendas";
        case "driver_expense":
          return "Despesa com Veículos";
        case "tax_expense":
          return "Despesa Tributária";
        case "occupancy_expense":
          return "Despesa com Ocupação";
        default:
          return "A Receber";
      }
    },
    typeFilter(value) {
      switch (value) {
        case "to_pay":
          return "A Pagar";
        case "to_receive":
          return "A Receber";
        default:
          return "Fatura";
      }
    },
    typeStatus(value) {
      switch (value) {
        case "scheduled":
          return "Agendada";
        case "to_receive":
          return "A Receber";
        default:
          return "Fatura";
      }
    }
  },
  methods: {
    ...mapActions(["abre_modal_view_invoice", "createConfirmAction"]),
    setMarcado(event, item) {
      console.log("event", event);
      // this.add_invoice_marcado(item)
    }
  }
};
</script>
